import React from "react";

import styles from "./feature-list.module.scss";

type FeatureProps = {
  title: string;
  description: string;
  iconUrl: string;
};

type FeatureListProps = {
  features: FeatureProps[];
};

const Feature: React.FC<FeatureProps> = ({ title, description, iconUrl }) => (
  <div className={styles.feature}>
    <div
      className={styles.icon}
      style={{ backgroundImage: `url('${iconUrl}')` }}
    />
    <div className={styles.title}>{title}</div>
    <p className={styles.description}>{description}</p>
  </div>
);

export const FeatureList: React.FC<FeatureListProps> = ({ features }) => (
  <div className={styles.wrapper}>
    {features.map((props, i) => (
      <Feature {...props} key={i} />
    ))}
  </div>
);
