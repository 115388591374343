import React from "react";

import styles from "./delivery-service.module.scss";

export const DeliveryService: React.FC = ({ children }) => (
  <div className={styles.wrapper}>
    <div className={styles.inner}>
      <div className={styles.icon}></div>
      <div>{children}</div>
    </div>
  </div>
);
