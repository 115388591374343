import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import styles from "./sec-img-sec.module.scss";

type Props = {
  firstSection: React.ReactNode;
  secondSection: React.ReactNode;
};

export const SectionImageSection: React.FC<Props> = ({
  firstSection,
  secondSection,
}) => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "baguetterie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <div className={styles.firstSection}>{firstSection}</div>
      <div className={styles.secondSection}>
        <div className={styles.outerImgWrapper}>
          <Img fluid={fluid} className={styles.img} />
        </div>
        <div className={styles.innerSection}>{secondSection}</div>
      </div>
    </>
  );
};
