import React from "react";

import { Layout } from "../components/shared/layout";
import { Helmet } from "../components/shared/helmet";
import { HomeSplash } from "../components/shared/home-splash";
import { SectionWrapper } from "../components/shared/section-wrapper";

import { Title } from "../components/shared/title";
import { ButtonLink } from "../components/shared/button-link";
import { DeliveryService } from "../components/home/delivery-service";

import { FeatureList } from "../components/shared/feature-list";

import { ImageFlex } from "../components/shared/image-flex";

import { SectionImageSection } from "../components/shared/sec-img-sec";

export default () => (
  <Layout>
    <Helmet />
    <HomeSplash />
    <SectionWrapper>
      <div>
        <Title doubled>
          EINFACH
          <br />
          GENIEßEN
        </Title>
        <p>
          Probieren Sie unsere Vielfalt an Spezalitäten und lassen Sie sich von
          unserem französischem Ambiente verzaubern. Mit unserem Team von einer
          exzellenten Köchin und Angestellten stehen wir Ihnen ganz zur
          Verfügung – damit Sie bei uns einen perfekten Aufenthalt verleben.
          Selbstverständlich sind unsere Produkte auch “to go” erhältlich.
        </p>
        <ButtonLink to="/speisekarten">Zu den Speisekarten</ButtonLink>
        <DeliveryService>
          <Title>LIEFERSERVICE</Title>
          <p>
            Wir liefern Kuchen und Gebäck. Melden Sie sich bei Bedarf gerne
            telefonisch oder schreiben Sie uns eine E-Mail:
            <br />
            <br />
            0157 - 70339184
            <br />
            <a href="mailto:krengelsys@t-online.de">krengelsys@t-online.de</a>
          </p>
        </DeliveryService>
      </div>
      <div>
        <FeatureList
          features={[
            {
              title: "Unser Mittagstisch",
              description:
                "Mit einem mediterranen leichten Mittagessen lassen Sie Ihre Mittagspause gleich richtig beginnen.",
              iconUrl: require("../images/features/food.svg"),
            },
            {
              title: "Bester Kaffee, frisch aus dem Süden",
              description:
                "Unsere Kaffeebohnen erstklassig für Sie ausgewählt.",
              iconUrl: require("../images/features/coffee.svg"),
            },
            {
              title: "Bestes Catering - lassen Sie sich beraten",
              description:
                "Fragen Sie unsere Mitarbeiter und lassen Sie sich über unserer Vielfalt beraten. Wir machen Ihnen schnellstmöglich einen Preisvorschlag.",
              iconUrl: require("../images/features/shopping.svg"),
            },
          ]}
        />
      </div>
      <div>
        <Title>UNSERE GASTRONOMIEN</Title>
        <p>
          Die Produkte für Simply Food werden liebevoll in unserem Petit Café
          auf der Marktstraße 58 hergestellt. Besuchen Sie uns gerne dort oder
          im Kultur Café Paris, Theaterplatz 2 oder bei Theater Veranstaltungen
          im Glasfoyer des Theaters, Theaterplatz 3.
        </p>
        <ImageFlex>
          <img
            src={require("../images/locations/petit-cafe-logo.svg")}
            alt="Petit Café Logo"
          />
          <img
            src={require("../images/locations/cafe-paris-logo.svg")}
            alt="Café Paris Logo"
          />
        </ImageFlex>
        <ButtonLink to="/cafe-paris">Kultur Café Paris</ButtonLink>
      </div>
      <SectionImageSection
        firstSection={
          <>
            <Title inverted>MACHEN SIE SICH EIN BILD VON UNS</Title>
            <ButtonLink to="/galerie" inverted>
              Zur Galerie
            </ButtonLink>
          </>
        }
        secondSection={
          <>
            <Title>EINZIGARTIGE UMGEBUNG</Title>
            <p>
              Unsere Mitarbeiter freuen sich darauf, Ihnen jeden Wunsch zu
              erfüllen.
            </p>
            <ButtonLink to="/kontakt">Kontakt</ButtonLink>
          </>
        }
      />
    </SectionWrapper>
  </Layout>
);
