import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-background-image";

import styles from "./home-splash.module.scss";
import splashLogoUrl from "../../../images/splash-logo.svg";

export const HomeSplash: React.FC = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "splash.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={styles.wrapper}>
      <div className={styles.imgWrapper}>
        <Img fluid={fluid} />
      </div>
      <div className={styles.logoWrapper}>
        <img src={splashLogoUrl} draggable={false} alt="Willkommensbild" />
      </div>
    </div>
  );
};
